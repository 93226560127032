<template>
    <v-container fluid class="px-8">
        <!-- 机构用户列表 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            :items-per-page-options="[5, 10, 15, -1]"
            class="elevation-2 row-pointer mt-5"
            :hide-default-footer="datas.length <= 10"
            :footer-props="{
                showFirstLastPage: true,
                itemsPerPageText: '每页行数',
                pageText: '',
                'items-per-page-all-text': '全部',
            }"
        >
            <template v-slot:no-data>
                暂无用户
            </template>
            <template v-slot:item.avatar="{ item }">
                <v-avatar color="white" size="36">
                    <v-img
                        :lazy-src="getAvatar(item.avatar)"
                        :src="getAvatar(item.avatar)"
                    ></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.nickname="{ item }">
                <div class="font-weight-medium">
                    <div class="active-name font-weight-medium" @click.stop="goto(item.class_id)" >
                        {{ item.nickname != "" ? item.nickname : "暂无" }}
                    </div>
                </div>
            </template>
            <template v-slot:item.name="{ item }">
                <div class="font-weight-medium">
                    <div class="active-name" @click.stop="goto(item.class_id)" >
                        {{ item.name != "" ? item.name : "匿名用户" }}
                    </div>
                </div>
            </template>
            <template v-slot:item.role="{ item }">
                <div>
                    {{ getRoleName(item.role) }}
                </div>
            </template>
            <template v-slot:item.phone="{ item }">
                <div>
                    {{ item.phone != "" ? item.phone : "未填写" }}
                </div>
            </template>
            <template v-slot:item.operate="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2 darken-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            elevation="2"
                            x-small
                            color="purple"
                            @click.stop="openEditStudentDlg(item)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑用户信息</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-2 darken-2"
                            icon
                            height="32"
                            width="32"
                            outlined
                            elevation="2"
                            x-small
                            color="red darken-1"
                            @click.stop="openConfirmDlg(item.uid)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除用户</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <ConfirmDlg
            ref="delStudentConfirmDlg"
            title="删除学生信息"
            text="确认 删除 当前学生信息么？"
            keyword=" 删除 "
            @confirm="delStudentConfirm"
        />

    </v-container>
</template>

<script>
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';
import { getAdminUserList, updateAdminStudent, delAdminStudent } from "@/api/admin";
import { getAvatar } from "@/utils/util";

export default {
    name: "TeacherList",
    data() {
        return {
            topicId: "",
            headers: [
                { text: "头像", value: "avatar", align: "center", sortable: false, width: 80 },
                { text: "昵称", value: "nickname", align: "left", sortable: false, },
                { text: "姓名", value: "name", align: "left", sortable: false, },
                { text: "角色", value: "role", align: "center", sortable: false, width: 80 },
                { text: "联系方式", value: "phone", align: "center", sortable: false },
                { text: "操作", value: "operate",  align: "center", sortable: false, width: 130 },
            ],
            datas: [],
        };
    },
    activated() {
        if (this.$route.query.id) {
            this.topicId = this.$route.query.id;
        }
        this.loadData();
    },
    methods: {
        getAvatar,
        getRoleName(role) {
            switch (role) {
                case "admin":
                    return "管理员"
                case "teacher":
                    return "教师"
                default:
                    return "角色"
            }
        },
        loadData() {
            getAdminUserList().then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        goto(id) {
            let pass;
            // this.$router.push({path: '/education-mgr/student/exercise', query: {id: id}});
        },
        openEditStudentDlg(student = null) {
            this.$refs.editStudentDlg.openDlg("补充学生信息", student);
        },
        openConfirmDlg(id) {
            this.$refs.delStudentConfirmDlg.openDlg(id);
        },
        submitStudent(student) {
            updateAdminStudent({ 
                studentId: student.uid,
                realName: student.realName,
                phone: student.phone
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    // 更新数据
                    for (let i in this.datas) {
                        if (this.datas[i].uid == student.uid) {
                            this.datas[i].real_name = student.realName
                            this.datas[i].phone = student.phone
                        }
                    }
                    
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "操作成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "操作未完成" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        delStudentConfirm(studentId) {
            delAdminStudent(studentId).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.datas.length; i++) {
                        if (this.datas[i].uid == studentId) {
                            this.datas.splice(i, 1)
                            break
                        }
                    }

                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "删除班级成功",
                    });

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "删除班级失败",
                    });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
    },

    components: {
        ConfirmDlg,
        // EditStudentDlg,
    }
};
</script>

<style lang="scss" scoped>
.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.avatar {
    height: 45px;
    width: 45px;
    img {
        height: 100%;
        width: 100%;
    }
}
</style>